<template>
      <div class="wrapper">
         <!-- 提交订单 -->
<!--        <headBack>-->
<!--          <template v-slot:title>-->
<!--            <div class='title'>-->
<!--                {{detail.TYPE == '4' ? detail.NAME : '提交订单'}}-->
<!--            </div>-->
<!--          </template>-->
<!--        </headBack>-->

        <div class='back_cont'>
          <div class="icon_box" @click='back'>
            <van-icon name="arrow-left" :color='"#313131"' class='backicon' :size='"24px"'/>
          </div>
          <div>   {{detail.TYPE == '4' ? detail.NAME : '提交订单'}}</div>
          <div class="kf"></div>
        </div>
<!--        <div class='header_cont'></div>-->
        <div class='header_cont'></div>
        <div class="detail">
          <div class="head" >
            <div class="left">
<!--              <div class="name">{{detail.NAME}}</div>-->
<!--              <div class="sub">-->
<!--                <span v-for="(k,v) in label">{{k}}</span>-->
<!--              </div>-->
              <img  class='b-img' :src="detail.HEAD_PIC_URL" alt="">
              <div class="xs" v-if="productId != '30'">
                <span>原价<s>￥{{detail.PRICE}}</s></span>
                <span class="sum"> 限时特价￥<span>{{detail.PROMOTION_PRICE}}</span></span>
              </div>
            </div>
<!--            <div class="right">-->
<!--              <img :src="detail.HEAD_PIC_URL" alt="">-->
<!--              <p class="cont" v-if="detail.TYPE != '4'">已有{{detail.SALE_NUM}}人报名</p>-->
<!--            </div>-->
          </div>
          <div class="cont">
               <div class="tit">请留下您的联系方式</div>
               <div class="put">基本信息</div>
               <div class="put">
                   <span>姓名</span>
                   <input type="text" placeholder="请输入姓名" v-model="uName">
               </div>
               <div class="put">
                  <div class="wx">
                     <div>手机号</div>
                     <div class="sub">(或微信号)</div>
                  </div>
                  <input type="text" placeholder="请输入手机号或者微信号" v-model="uPhone">
               </div>
          </div>
          <div class="btn" @click="toPay">{{detail.TYPE == '4' ? '立即报名咨询' : '立即支付'}}</div>
          <div class="copytxt" style="opacity: 0">yihudaojia2022</div>
        </div>
      </div>
</template>

<script>
import {reactive,ref} from 'vue';
import homeApi from '@axios/home'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import {Toast , Dialog} from "vant";
export default {
  name: "order",
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()

    const active_color = ref('')
    const detail = ref('');
    const label = ref('');
    const productId = route.query.productId;
    const type = route.query.type;

    const getDetail = () => {
      let formData = new FormData();
      formData.append('data', JSON.stringify({
        function: "activityProductInfo",
        productId: route.query.productId,
        userId: store.state.uid,
        newversion: '2120'
      }))
      formData.append('encryption', false)

      homeApi.getList(formData).then((res) => {

        console.log(res);
        detail.value = res.data;
        label.value = res.data.LABEL.split('、')
        // active_color.value = res.data.TABBAR_COLOR
        // list.value = res.data;
      })
    }
    getDetail()


    const copyArticle =() => {
      const range = document.createRange();
      range.selectNode(document.querySelector(".copytxt"));
      const selection = window.getSelection();if(selection.rangeCount > 0) selection.removeAllRanges();
      selection.addRange(range);document.execCommand('Copy');
    }
    const textCopy  = (t)=> {

     // 如果当前浏览器版本不兼容navigator.clipboard
      if (!navigator.clipboard) {
        var ele = document.createElement("input");
        ele.value = "yihudaojia2022";
        document.body.appendChild(ele);
        ele.select();
        document.execCommand("copy");
        document.body.removeChild(ele);
        if (document.execCommand("copy")) {
          console.log("复制成功！");
        } else {
          console.log("复制失败！");
        }

      } else {
        navigator.clipboard.writeText("yihudaojia2022").then(function () {
          console.log("复制成功！");
        }).catch(function () {
          console.log("复制失败！");
        })
      }


    }

    const uName  = ref ('');
    const uPhone  = ref ('');
    const toPay = () => {
         if(!uName.value || !uPhone){
           Toast('请完善基本信息');
           return false;
         }
      let formData = new FormData();
      formData.append('data', JSON.stringify({
        function: "activityOrderSave",
        productId: route.query.productId,
        userId: store.state.uid,
        userMobile:uPhone.value,
        productName:detail.value.NAME,
        price:detail.value.PROMOTION_PRICE,
        userName:uName.value,
        type:detail.value.TYPE,
        wechatNumber:'',
        remark:'',
        newversion: '2120'
      }))
      formData.append('encryption', false)

      homeApi.getList(formData).then((res) => {
          if(res.code == '0000'){
            if(detail.value.TYPE == '4'){
              // Toast({
              //   message:'稍后会有咨询顾问与您联系，如有疑问，可拨打400-800-6996',
              //   // duration:0
              // });

              Dialog.alert({
                title: '报名成功',
                message: '您也可以添加客服咨询，了解具体信息',
                confirmButtonText:'复制微信号',
                confirmButtonColor:'rgb(0, 194, 145)'
              }).then(() => {
                // textCopy('yihudaojia2022')
                copyArticle()
                Dialog.alert({
                  title: '提示',
                  message: '您已成功复制微信号',
                  confirmButtonColor:'rgb(0, 194, 145)'
                }).then(() => {
                  // window.location.href ='http://m.yihu365.cn/public.shtml';
                });
              });

              return;
            }


            router.push({
              name:'communityPay',
              query:{
                fee:detail.value.PROMOTION_PRICE,
                orderId:res.data.orderId,
                productId:route.query.productId
              }
            })


          }
      })

    }

    let u = navigator.userAgent;
    console.log(u)
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    let ua = u.toLowerCase();
    let isWx = ua.match(/MicroMessenger/i) == 'micromessenger';
    const back = ()=> {

      console.log('111')
      if(isWx){
        router.back();
        return;
      }
      if((isAndroid || isiOS) && type){
        window.location.href ='http://m.yihu365.cn/public.shtml';
        return;
      }else{
        router.back();
      }

     // router.back();

    }
    return {
      detail,
      toPay,
      uName,
      uPhone,
      label,
      back,
      productId
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper{
  min-height: 100vh;
  //background-color: rgba(120,177,255,.8);
  background: linear-gradient(to bottom, #4BA3FB 0%, #FFF 80%);
}
.detail{
  box-sizing: border-box;
  padding:30px;
}
.time{
  box-sizing: border-box;
  background-color: #FFFFFF;
  padding:30px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  font-size: 28px;
  .names{
    color:rgb(120,177,255);
  }
}
.cont{
  box-sizing: border-box;
  background-color: #FFFFFF;
  padding:30px 30px 34px;
  border-radius: 16px;
  margin-top: 30px;
  .tit{
    text-align: center;
    font-size: 32px;
  }
  .put{
     box-sizing: border-box;
     padding:22px 0;
     border-bottom: 1px solid #faf9f9;
     display: flex;
     justify-content: space-between;
    font-size: 30px;
    .wx{
      display: flex;
     // flex-direction: column;
     align-items:flex-end;
      .sub{
        color:#999999;
        font-size: 24px;
      }
    }
     input{
       text-align: right;
       border:none;
     }
  }
}
.btn{
  margin:200px auto;
   width:85%;
  height:90px;
  background-color:#3D9DFC;
  border-radius: 50px;
  line-height: 90px;
  text-align: center;
  color:#FFFFFF;
}
.head{
  box-sizing: border-box;
  background-color: #FFFFFF;
  padding:30px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  .left{
.b-img{
  width: 100%;
}
    .name{
      color:rgb(120,177,255);
      font-size: 32px;
    }
    .sub{
      display: flex;
      margin:16px 0 16px 0px;
      span{
        display: block;
        margin-right: 16px;
        border-radius: 6px;
        font-size: 26px;
        border:1px solid #F7DAB7;
        padding:4px 8px;
        color:#E5B379;
        background: #FDE1E0;
      }
    }
    .xs{
      font-size: 28px;
      margin-top: 10px;
      color:#333;
      .sum{
        color:#FF6C00;
        margin-left: 20px;
        span{
          font-size: 38px;
        }
      }
    }
  }
  .right{
    img{
      width: 260px;
    }
    p{
      margin: 0;
      font-size: 26px;
      text-align: center;
      color:#999999;
    }
  }
}

.img_box{
  padding-bottom: 100px;
  img{
    width: 100%;
    display: block;
  }

}
.bottom_fix{

  position: fixed;
  left:0;
  bottom:0;
  z-index: 100;
  height:120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 30px;
  box-shadow:0px -15px 20px -12px rgb(120,177,255);
  box-sizing: border-box;
  padding:0 30px;
  background-color: rgb(120,177,255);
  .btn{
    color:#fff;
    background-color: #999999;
    padding: 16px 70px;
    border-radius: 40px;
  }
  .btn1{
    background-color: #5454DE;
  }
  .btn2{
    background-color: #FF6C00;
  }
}

.back_cont{
  width: 100%;
  position: fixed;
  display: flex;
  //justify-content: center;
  align-items: center;
  justify-content: space-between;
  height: 88px;
  line-height: 88px;
  border-bottom: 1px solid #F5F5F5;
  box-sizing: border-box;
  font-size: 34px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313131;
  background: #fff;
  z-index: 9999;
  .icon_box{
    margin-left: 10px;
    box-sizing: border-box;
    padding-top: 12px;
  }
  .backicon{
    //position: absolute;
    //left: 15px;
    //width: 20px;

  }
}
.kf{
  width: 10px;
}

</style>
