import { render } from "./order.vue?vue&type=template&id=be3ad206&scoped=true"
import script from "./order.vue?vue&type=script&lang=js"
export * from "./order.vue?vue&type=script&lang=js"

import "./order.vue?vue&type=style&index=0&id=be3ad206&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-be3ad206"
/* hot reload */
if (module.hot) {
  script.__hmrId = "be3ad206"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('be3ad206', script)) {
    api.reload('be3ad206', script)
  }
  
  module.hot.accept("./order.vue?vue&type=template&id=be3ad206&scoped=true", () => {
    api.rerender('be3ad206', render)
  })

}

script.__file = "src/views/community/order/order.vue"

export default script